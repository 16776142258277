import { getRequest } from 'utils/rest-api-util';

export const getDownloadsSearchConsole = async (
  analyticId: string,
  searchParams?: URLSearchParams,
): Promise<Response> => {
  const requestPath = `/api/v1/analytics/${analyticId}/downloads/search_console.xlsx`;

  return await getRequest(requestPath, searchParams, 'blob', 60000);
};
